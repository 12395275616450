import React, { useState } from 'react';
import './App.css';
import giftsyLogo from './logos/Logo.svg';
import leftbox from './logos/leftbox-logo.svg'
import rightbox from './logos/rightbox.svg'
import middlebox from './logos/middlebox.svg'
import numberOne from './logos/number-one.svg'
import numberTwo from './logos/number-two.svg'
import numberThree from './logos/number-three.svg'
// import 'typeface-montserrat';


async function joinWaitlist(email: string): Promise<void> {
  const apiUrl = "https://ciazqymbij.execute-api.us-east-1.amazonaws.com/dev/users";

  const options: RequestInit = {
    method: "POST",
    headers: {
      "x-api-key": "AfrGarMxzoNDS46HdD5g6LF8Zod1X8v1nvqXGOa4",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: email,
    }),
  };

  try {
    const response = await fetch(apiUrl, options);
    console.log(response)
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    alert("You have been added to the waitlist!");
  } catch (error) {
    alert(`Error: ${error}`);
  }
}

function App() {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleJoinWaitlist = () => {
    if (getIsValidEmail()) {
      joinWaitlist(email);
    } else {
      alert('Not a valid email. Please try again!');
      setIsValidEmail(getIsValidEmail())
    }
  };

  const getIsValidEmail = () => {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsValidEmail(true)
  };

  return (
    <div className="mainpage">
      <div className="header">
        <img src={giftsyLogo} className="Giftsy-logo" alt="Giftsy-logo" />
      </div>
      <div className='join-waitList'>
        <h1> Gifting made easy.</h1>
        <div className='join-waitList-intro'>
          <p >
            Take the stress out of gift-giving and find the perfect
            gift in minutes.
          </p>
        </div>

        <div className='join-waitList-input'>
          <div className='join-waitList-input'>
            <label>Join our waitlist to be the first to try Giftsy</label>
            <input
              type='email'
              placeholder='Email'
              value={email}
              onChange={handleEmailChange}
              pattern=".+@globex\.com"
              required />
            <button className='joinButton' onClick={handleJoinWaitlist}>Join waitlist</button>
          </div>
        </div>

      </div>

      <div className="IntroPoints">
        <div id="leftbox" className="steps">
          <img src={leftbox} className="leftbox-logo" alt="leftbox-logo" />
          <img className='number' src={numberOne} />
          <h2>Tell us about your giftee.</h2>
          <p>Take 5 minutes to tell us about their personality and their favorite things.</p>
        </div>

        <div id="middlebox" className="steps">
          <img src={middlebox} className="middlebox-logo" alt="middlebox-logo" />
          <img className='number' src={numberTwo} />
          <h2>Get a personalized gift list.</h2>
          <p>Within seconds, you'll have a unique list of gifts to choose from.</p>
        </div>

        <div id="rightbox" className="steps">
          <img src={rightbox} className="rightbox-logo" alt="rightbox-logo" />
          <img className='number' src={numberThree} />
          <h2>Shop. Ship. Done.</h2>
          <p>You can take all the credit when they call your gift their favorite.</p>
        </div>
      </div>
      <div className='footer'>
        <p><> <b>Questions?</b>&nbsp;Send us an email at&nbsp; </> </p>
        <a href="mailto:support@trygiftsy.com" className="link"> support@trygiftsy.com </a>
      </div>
    </div>
  );
}

export default App;
